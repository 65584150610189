import {initPromoStaticcontent} from "./init";
import {BrandhubBackground} from './brandhub_background';
import {eventQBus} from "@otto-ec/global-resources/event-q-bus";
import {eventLoader} from "@otto-ec/global-resources/event-loader";

eventQBus.on("ft3.promo-staticcontent.init", () => {
    initPromoStaticcontent();
});

eventQBus.on("ft3.promo-staticcontent.brandhub-background.init", () => {
    new BrandhubBackground();
});

 eventLoader.onAllPreloadScriptsLoaded(95, () => {
    eventQBus.emit("ft3.promo-staticcontent.init")
});
